@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-backgroundPrimary: 40 33 40;
    --color-backgroundSecondary: 20 20 20;
    --color-textPrimary: 255 255 255;
    --color-textSecondary: 143 143 143;
    --color-buttonPrimary: 152 92 166;
    --color-progressBarColor: 25 128 56;
    --color-inputBackgroundColor: 255 255 255;
    --color-inputTextColor: 20 20 20;
    --size-progressBarHeight: 24px;
    --color-errorColor: 255 64 64;
    --color-warningColor: 255 151 0;
    --color-successColor: 25 128 56;
    --color-buttonSecondary: 216 216 216;
  }

  * {
    box-sizing: border-box;
  }

  body {
    @apply text-colorTextBase;
    @apply bg-colorBgBase;
    @apply relative;
    @apply font-roboto;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply text-colorTextBase;
  }
}

html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

#root {
  width: 100%;
  height: 100%;
}

.ant-dropdown-menu-item {
  padding: 8px 10px !important;
}

#nprogress .bar {
  @apply bg-colorPrimary;
  height: 2px;
  z-index: 99999;
}

#nprogress .peg {
  @apply shadow-colorPrimary;
  box-shadow:
    0 0 10px var(--tw-shadow-color),
    0 0 5px var(--tw-shadow-color);
}

.pac-item:hover {
  cursor: pointer;
  @apply bg-gray-200;
}
