.phantom-table {
  .ag-theme-quartz-dark {
    --ag-foreground-color: theme(colors.colorTextBase);
    --ag-background-color: theme(colors.colorBgBase);
    --ag-header-foreground-color: theme(colors.colorTextBase);
    --ag-header-background-color: theme(colors.colorPrimaryBg);
    --ag-odd-row-background-color: theme(colors.colorFillTertiary);
    --ag-active-color: theme(colors.colorPrimary);
    --ag-border-color: theme(colors.colorBorder);
    --ag-row-border-color: theme(colors.colorBgBase);
    --ag-row-hover-color: theme(colors.colorFillSecondary);
    --ag-header-cell-hover-background-color: theme(colors.colorPrimaryBgHover);
  }
  /* --ag-list-item-height: 20px; */
  /* .ag-header-cell-label {
    font-style: italic;
    color: red;
  }

  .ag-header {
    background-color: theme(colors.colorPrimary);
  } */
}
